<template>
  <div class="container">

    <!-- HEADER -->
    <div class="header">
      <div class="step-count">{{ $t('smartcardItsmeDeactivation.deactivationStep', { step: step, maxStep: 2 }) }}</div>
      <StyledH1>{{ $t('smartcardItsmeDeactivation.deactivationHeader', [getTokenType()]) }}</StyledH1>
    </div>

    <!-- MAIN -->

    <div v-if="step == 1" class="main">
      <div class="instructions">
        <p>{{ $t('smartcardItsmeDeactivation.deactivationConfirmation1', [getTokenType()]) }}</p>
        <p>{{ $t('smartcardItsmeDeactivation.deactivationConfirmation2', [getTokenType()]) }}</p>
      </div>
    </div>
    <div v-if="step == 2" class="main">
      <div class="instructions">
        <ol>
          {{ $t('smartcardItsmeDeactivation.deactivationStep1', [getTokenType()]) }}
        </ol>
      </div>
    </div>

    <!-- FOOTER -->
    <div class="footer">
      <StyledButton
          v-if="step < 2"
          v-on:click="goToProfile()">
        {{ $t('smartcardItsmeDeactivation.cancel') }}
      </StyledButton>

      <StyledButton
          :filled=true
          v-on:click="dispatch(step)"
          v-on:keyup.enter="dispatch(step)"
          ref="nextButton">
        {{ getButtonLabel(step) }}
      </StyledButton>
    </div>

  </div>
</template>

<style scoped>

.container {
  display: flex;
  flex-direction: column;
}

h1 {
  margin: 0px;
}

.footer {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}

.main {
  display: flex;
  flex-direction: row;
  line-height: 28px;
  font-weight: 300;
  letter-spacing: 0.33px;
}

.instructions {
  flex-grow: 1;
}

img {
  width: 200px;
}

.step-count {
  color: rgb(180, 180, 180);
  font-size: 11px;
}

ol {
  counter-reset: item;
  margin-left: 1em;
  margin-top: 30px;
  padding-left: 0px;
}

ol li {
  display: block;
}

ol li::before {
  display: inline-block;
  content: counter(item)".";
  counter-increment: item;
  font-size: 13px;
  font-weight: bold;
  width: 1.2em;
  margin-left: -1.2em;
}

input {
  width: 80%;
  padding: 5px;
  border: 1px solid rgb(200, 200, 200);
}

button {
  width: 45%;
}
</style>

<script>
import axiosApi from '@/services/AxiosService'
import UserManager from '@/services/SecurityService'
import Const from '@/services/Constants'

import StyledButton from '@/components/styled/StyledButton'
import StyledH1 from '@/components/styled/StyledTitle'
import TokenService from "@/services/TokenService";

export default {
  components: {
    StyledButton,
    StyledH1
  },
  props: {
    token: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      userManager: new UserManager(),
      tokenService: new TokenService(),
      isabelUserId: null,
      step: 1,
      challenge: null,
    }
  },
  created () {
    /* Set the event management when we click on enter. */
    const component = this
    this.keyHandler = function (event) {
      if (event.keyCode === 13) {
        component.triggerNext()
      }
    }
    window.addEventListener('keyup', this.keyHandler)

    this.userManager.getUserId().then((userId) => {
      this.isabelUserId = userId
    })
  },
  beforeDestroy () {
    window.removeEventListener('keyup', this.keyHandler)
  },
  methods: {
    dispatch (step) {
      switch (step) {
        case 1:
          this.sendUserIdTokenId()
          break
        case 2:
          this.goToProfile()
          break
      }
    },
    getButtonLabel (step) {
      if (step === 1) {
        return this.$t('smartcardItsmeDeactivation.startDeactivation')
      }
      return this.$t('common.close')
    },
    sendUserIdTokenId () {
      axiosApi
          .delete(`/users/${this.isabelUserId}/tokens/${this.token.tokenId}`)
          .then(() => {
            this.$emit('errorPath', null)
            this.step++
          })
          .catch((error) => {
            this.handleErrorCodes(error)
            this.$refs.serial.focus()
          })
    },
    handleErrorCodes (error) {
      const paths = {
        INVALID_TOKEN_STATE: 'invalidState'
      }
      this.$emit('errorPath', 'smartcardItsmeDeactivation.errors.' + (paths[error?.response?.data?.errors[0]?.title] ?? 'technical'))
    },
    triggerNext () {
      this.$refs.nextButton.click()
    },
    goToProfile () {
      this.$router.push(Const.router.profile)
    },
    getTokenType () {
      if (this.tokenService.isSmartcard(this.token.type)) {
        return 'smartcard'
      }
      return this.token.type.toLowerCase()
    }
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
      }
    }
  }
}
</script>
